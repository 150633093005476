/**
 * @description: project管理页面接口列表
 */
import request from './request'

// 获取project列表
export function get_project_list (params) {
  return request({
    url: 'project_api/project_list',
    method: 'get',
    params
  })
}

// 获取默认project
export function not_del_projects () {
  return request({
    url: '/project_api/not_del_projects',
    method: 'get'
  })
}

// 获取project详情
export function get_project_detail (params) {
  return request({
    url: '/project_api/project',
    method: 'get',
    params
  })
}

// 获取所有 group name
export function get_all_groupName () {
  return request({
    url: '/config_api/group_name_list',
    method: 'get'
  })
}

// 获取所有 project
export function get_all_projectName () {
  return request({
    url: '/project_api/project_name_list',
    method: 'get'
  })
}

// 获取 group 对应的 profile
export function get_profile_by_group (params) {
  return request({
    url: '/config_api/group_profiles',
    method: 'get',
    params
  })
}

// 获取 profile 列表
export function get_profile_list () {
  return request({
    url: '/config_api/group_profile_list',
    method: 'get'
  })
}

// 删除project
export function delete_project (data) {
  return request({
    url: '/project_api/project',
    method: 'delete',
    data
  })
}

// 修改project
export function update_project (data) {
  return request({
    url: '/project_api/project',
    method: 'put',
    data
  })
}

// 添加project
export function add_project (data) {
  return request({
    url: '/project_api/project',
    method: 'post',
    data
  })
}

// 下发project
export function send_project (data) {
  return request({
    url: '/project_api/send_to_all_server',
    method: 'post',
    data
  })
}

// 获取impl与format之间的对应关系
export function get_map_impl_format () {
  return request({
    url: '/project_api/impl_format_map',
    method: 'get'
  })
}
// 获取imanager服务器列表
export function get_imanager_servers () {
  return request({
    url: '/server_api/imanager_servers',
    method: 'get'
  })
}
// 检查
export function check_project_with_server (params) {
  return request({
    url: '/project_api/check_project_with_server',
    method: 'get',
    params
  })
}
// 检查
export function load_project (params) {
  return request({
    url: '/project_api/load_project',
    method: 'get',
    params
  })
}

// 下载 project文件
export function export_project (params) {
  return request({
    url: '/project_api/export_project',
    method: 'get',
    params
  })
}
