/**
 * @description: 配置管理页面接口列表
 */

import request from './request'

// 获取group列表
export function group_list () {
  return request({
    url: '/config_api/group_list',
    method: 'get'
  })
}

/**
 * @description: 增加group配置
 * @param {
 *  group_name: String, group名称,
 *  group_config: Object, group配置
 * }
 */
export function add_group (data) {
  return request({
    url: '/config_api/group',
    method: 'post',
    data
  })
}

/**
 * @description: 更新group配置
 * @param {
 *  group_name: String, group名称,
 *  group_config: Object, group配置
 * }
 */
export function update_group (data) {
  return request({
    url: '/config_api/group',
    method: 'put',
    data
  })
}

/**
 * @description: 删除group配置
 * @param {
 *  group_name: String, group名称
 * }
 */
export function delete_group (data) {
  return request({
    url: '/config_api/group',
    method: 'delete',
    data
  })
}

/**
 * @description: 下发group配置
 * @param {
 *  group_name: String, group名称,
 *  server_id_list: Array, 服务器信息列表
 * }
 */
export function send_group (data) {
  return request({
    url: '/config_api/send_group_config',
    method: 'post',
    data
  })
}

// 下发到所有服务器
export function set_all (data) {
  return request({
    url: '/config_api/send_to_all_server',
    method: 'post',
    data
  })
}

// 检测Profile是否被使用
export function check_profile (data) {
  return request({
    url: '/config_api/check_profile',
    method: 'post',
    data
  })
}

// 获取group列表
export function get_group_list (params) {
  return request({
    url: 'config_api/group_list',
    method: 'get',
    params
  })
}

// 获取单个group
export function get_group_detail (params) {
  return request({
    url: '/config_api/group',
    method: 'get',
    params
  })
}
// 获取不能删除的group
export function not_del_groups () {
  return request({
    url: '/config_api/not_del_groups',
    method: 'get'
  })
}

// 获取场景名称及其部分参数限制
export function get_profile_config () {
  return request({
    url: '/config_api/push_profiles',
    method: 'get'
  })
}

// 获取场景名称及其部分参数限制
export function get_server_list_select () {
  return request({
    url: '/server_api/servers',
    method: 'get'
  })
}

// 获取转推配置列表
export function group_rtmp_list (params) {
  return request({
    url: '/config_api/group_turn_push_list',
    method: 'get',
    params
  })
}

// 添加转推配置
export function add_rtmp (data) {
  return request({
    url: '/config_api/group_turn_push',
    method: 'post',
    data
  })
}

// 修改转推配置
export function update_rtmp (data) {
  return request({
    url: '/config_api/group_turn_push',
    method: 'put',
    data
  })
}
// 删除转推配置
export function delete_rtmp (data) {
  return request({
    url: '/config_api/group_turn_push',
    method: 'delete',
    data
  })
}

// 下发转推配置
export function send_rtmp (data) {
  return request({
    url: '/config_api/publish_group_turn_push',
    method: 'post',
    data
  })
}

// 下载group文件
export function export_group (params) {
  return request({
    url: '/config_api/export_group',
    method: 'get',
    params
  })
}
