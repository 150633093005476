<template>
  <div>
    <div class="operate">
      <el-page-header
        :content="$t('addGroup.title')"
        @back="goBack"
      />
      <div class="tool">
        <div class="op">
          <el-button
            type="primary"
            @click="confirmAddGroup"
          >
            {{ $t("common.confirmAdd") }}
          </el-button>
          <el-button
            type="primary"
            @click="addProfile"
          >
            {{ $t("config.addProfile") }}
          </el-button>
          <el-button
            type="primary"
            style="marginRight: 10px"
            @click="copyConfig"
          >
            {{ $t("addGroup.clone") }}
          </el-button>
          <el-select
            v-model="copyGroup"
            :popper-append-to-body="false"
            :placeholder="$t('config.copyGroup')"
            clearable
          >
            <el-option
              v-for="it in groupOptions"
              :key="it"
              :label="it"
              :value="it"
            />
          </el-select>
        </div>
      </div>
    </div>
    <el-collapse
      v-model="activeNames"
      class="penetrate "
    >
      <el-collapse-item
        v-for="(it, idx) in tmp"
        :key="idx"
        style="position: relative"
        :title="idx"
        :name="idx"
      >
        <el-button
          v-if="idx != 'BASE' && idx !='PROFILE_0'"
          type="danger"
          class="profileDelete"
          @click="deleteProfile(idx)"
        >
          {{ $t("config.deleteProfile") }}
        </el-button>
        <el-form
          ref="addForm"
          :model="it"
          :rules="rules"
          inline
          label-width="200px"
        >
          <template v-if="idx === 'BASE'">
            <el-tooltip
              effect="light"
              placement="top"
              :hide-after="3000"
              :content="$t('addGroup.groupName_manual')"
            >
              <el-form-item
                :label="$t('addGroup.groupName')"
                prop="groupName"
              >
                <el-input
                  v-model="it.groupName"
                  @focus="changeField(idx)"
                />
              </el-form-item>
            </el-tooltip>
            <el-tooltip
              effect="light"
              placement="top"
              :hide-after="3000"
              :content="$t('config.PUSH_BASE_URL_manual')"
            >
              <el-form-item
                :label="$t('config.PUSH_BASE_URL')"
                prop="PUSH_BASE_URL"
              >
                <el-input
                  v-model="it.PUSH_BASE_URL"
                  @focus="changeField(idx)"
                />
              </el-form-item>
            </el-tooltip>
          </template>
          <template v-else>
            <div>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.SE_Name_manual')"
              >
                <el-form-item
                  :label="$t('config.SE_Name')"
                  prop="SE_Name"
                >
                  <el-popover
                    placement="bottom"
                    width="700"
                    trigger="focus"
                  >
                    <el-radio-group
                      v-model="it.SE_Name"
                      :disabled="idx != 'PROFILE_0'"
                    >
                      <el-tooltip
                        v-for="radio in seNameList"
                        :key="radio"
                        effect="light"
                        placement="top"
                        :hide-after="3000"
                        :content="toolTip[lang][radio]"
                      >
                        <el-radio
                          style="width:100px; marginBottom:5px"
                          :label="radio"
                          @change="handleChange(radio, idx)"
                        >
                          {{ radio }}
                        </el-radio>
                      </el-tooltip>
                    </el-radio-group>
                    <el-input
                      slot="reference"
                      v-model="it.SE_Name"
                      readonly
                      :disabled="idx != 'PROFILE_0'"
                      @focus="changeField(idx)"
                    />
                  </el-popover>
                </el-form-item>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.Min_BR_manual')"
              >
                <el-form-item
                  :label="$t('config.Min_BR')"
                  prop="Min_BR"
                >
                  <el-input
                    v-model.number="it.Min_BR"
                    :placeholder="`default(${$t('common.minis')}${limitList[idx].min_br}kbps)`"
                    @focus="changeField(idx)"
                  />
                </el-form-item>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.Max_BR_manual')"
              >
                <el-form-item
                  :label="$t('config.Max_BR')"
                  prop="Max_BR"
                >
                  <el-input
                    v-model.number="it.Max_BR"
                    :placeholder="`default(${$t('common.maxis')}${limitList[idx].max_br}kbps)`"
                    @focus="changeField(idx)"
                  />
                </el-form-item>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.Max_FPS_manual')"
              >
                <el-form-item
                  :label="$t('config.Max_FPS')"
                  prop="Max_FPS"
                >
                  <el-input
                    v-model.number="it.Max_FPS"
                    :placeholder="`default(${$t('common.maxis')}${limitList[idx].fps}fps)`"
                    @focus="changeField(idx)"
                  />
                </el-form-item>
              </el-tooltip>
            </div>
            <div>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.Max_RES_WIDTH_manual')"
              >
                <el-form-item
                  :label="$t('config.Max_RES_WIDTH')"
                  prop="Max_RES_Width"
                >
                  <el-input
                    v-model.number="it.Max_RES_Width"
                    :placeholder="`default(${$t('common.maxis')}${limitList[idx].width})`"
                    @focus="changeField(idx)"
                  />
                </el-form-item>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.Max_RES_HEIGHT_manual')"
              >
                <el-form-item
                  :label="$t('config.Max_RES_HEIGHT')"
                  prop="Max_RES_Height"
                >
                  <el-input
                    v-model.number="it.Max_RES_Height"
                    :placeholder="`default(${$t('common.maxis')}${limitList[idx].height})`"
                    @focus="changeField(idx)"
                  />
                </el-form-item>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.HD_Decode_Flag_manual')"
              >
                <el-form-item
                  :label="$t('config.HD_Decode_Flag')"
                  prop="HD_Decode_Flag"
                >
                  <el-select
                    v-model="it.HD_Decode_Flag"
                    :popper-append-to-body="false"
                    class="reducePadding"
                    @focus="changeField(idx)"
                  >
                    <el-option
                      v-for="radio in HD_Decode_FlagList"
                      :key="radio.option_value"
                      :value="radio.option_value"
                      :label="radio.option_label"
                    />
                  </el-select>
                </el-form-item>
              </el-tooltip>
              <el-tooltip
                effect="light"
                placement="top"
                :hide-after="3000"
                :content="$t('config.HD_Render_Flag_manual')"
              >
                <el-form-item
                  :label="$t('config.HD_Render_Flag')"
                  prop="HD_Render_Flag"
                >
                  <el-select
                    v-model="it.HD_Render_Flag"
                    :popper-append-to-body="false"
                    class="reducePadding"
                    @focus="changeField(idx)"
                  >
                    <el-option
                      v-for="radio in HD_Render_FlagList"
                      :key="radio.option_value"
                      :value="radio.option_value"
                      :label="radio.option_label"
                    />
                  </el-select>
                </el-form-item>
              </el-tooltip>
              <div>
                <el-tooltip
                  effect="light"
                  placement="top"
                  :hide-after="3000"
                  :content="$t('config.displayMode')"
                >
                  <el-form-item :label="$t('config.displayMode')">
                    <el-select
                      v-model="it.Display_Mode"
                      :popper-append-to-body="false"
                      class="reducePadding"
                      @focus="changeField(idx)"
                    >
                      <el-option
                        v-for="radio in displayModeList"
                        :key="radio.option_value"
                        :value="radio.option_value"
                        :label="radio.option_label"
                      />
                    </el-select>
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  effect="light"
                  placement="right"
                  :hide-after="3000"
                  :content="$t('config.mute')"
                >
                  <el-form-item
                    :label="$t('config.mute')"
                    prop="mute"
                  >
                    <el-checkbox
                      v-model="it.mute"
                      style="margin-right: 165px"
                      true-label="1"
                      false-label="0"
                      @focus="changeField(idx)"
                    />
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  effect="light"
                  placement="right"
                  :hide-after="3000"
                  :content="$t('config.Disable_Interaction')"
                >
                  <el-form-item
                    :label="$t('config.Disable_Interaction')"
                    prop="Disable_Interaction"
                  >
                    <el-checkbox
                      v-model="it.Disable_Interaction"
                      style="margin-right: 165px"
                      true-label="1"
                      false-label="0"
                      @focus="changeField(idx)"
                    />
                  </el-form-item>
                </el-tooltip>
                <el-tooltip
                  effect="light"
                  placement="right"
                  :hide-after="3000"
                  :content="$t('config.onDemand')"
                >
                  <el-form-item
                    :label="$t('config.onDemand')"
                    prop="Content_Mode_Default"
                  >
                    <el-checkbox
                      v-model="it.Content_Mode_Default"
                      true-label="1"
                      false-label="0"
                      @focus="changeField(idx)"
                    />
                  </el-form-item>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-form>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { get_all_groupName } from '@/api/project'
import { add_group, get_group_detail, get_profile_config } from '@/api/config.js'
import { get_sename_list } from '@/api/api.js'
export default {
  data () {
    const validateMin_BR = (rule, value, callback) => {
      if (Number(value) > this.limitList[this.currentField].max_br) {
        callback(new Error(`${this.$t('common.maxis')}${this.limitList[this.currentField].max_br}`))
      } else if (value === undefined || value == 0 || value == 'default' || Number(value) >= this.limitList[this.currentField].min_br) {
        callback()
      } else {
        callback(new Error(`${this.$t('common.minis')}${this.limitList[this.currentField].min_br}`))
      }
    }
    const validateMax_BR = (rule, value, callback) => {
      if (value === undefined || value == 0 || value == 'default') {
        callback()
      } else if (Number(value) > this.limitList[this.currentField].max_br) {
        callback(new Error(`${this.$t('common.maxis')}${this.limitList[this.currentField].max_br}`))
      } else if (Number(value) < this.tmp[this.currentField].Min_BR) {
        callback(new Error(`${this.$t('common.notLess')}${this.tmp[this.currentField].Min_BR}`))
      } else if (Number(value) < this.limitList[this.currentField].min_br) {
        callback(new Error(`${this.$t('common.notLess')}${this.limitList[this.currentField].min_br}`))
      } else {
        callback()
      }
    }
    const validateMax_FPS = (rule, value, callback) => {
      if (value === undefined || value == 0 || value == 'default' || Number(value) <= this.limitList[this.currentField].fps) {
        callback()
      } else {
        callback(new Error(`${this.$t('common.maxis')}${this.limitList[this.currentField].fps}`))
      }
    }
    const validateMax_RES_WIDTH = (rule, value, callback) => {
      if (value === undefined || value == 0 || value == 'default' || Number(value) <= this.limitList[this.currentField].width) {
        callback()
      } else {
        callback(new Error(`${this.$t('common.maxis')}${this.limitList[this.currentField].width}`))
      }
    }
    const validateMax_RES_HEIGHT = (rule, value, callback) => {
      if (value === undefined || value == 0 || value == 'default' || Number(value) <= this.limitList[this.currentField].height) {
        callback()
      } else {
        callback(new Error(`${this.$t('common.maxis')}${this.limitList[this.currentField].height}`))
      }
    }
    return {
      copyGroup: '',
      lang: localStorage.lang,
      groupOptions: [],
      currentField: 'PROFILE_0',
      activeNames: ['BASE', 'PROFILE_0'],
      seNameList: [],
      pslVerboseLogList: [],
      ptcpLogList: [],
      HD_Decode_FlagList: [],
      displayModeList: [],
      HD_Render_FlagList: [],
      tmp: {},
      limitList: {
        PROFILE_0: {},
        PROFILE_1: {},
        PROFILE_2: {},
        PROFILE_3: {}
      },
      rules: {
        PUSH_BASE_URL: [{ required: true, message: this.$t('config.inputServer'), trigger: ['blur', 'change'] }],
        psl_verbose_log: [{ required: true, message: this.$t('config.select_psl_verbose_log'), trigger: ['blur', 'change'] }],
        ptcp_log: [{ required: true, message: this.$t('config.select_ptcp_log'), trigger: ['blur', 'change'] }],
        SE_Name: [{ required: true, message: this.$t('config.select_SE_Name'), trigger: ['blur', 'change'] }],
        HD_Decode_Flag: [{ required: true, message: this.$t('config.select_HD_Decode_Flag'), trigger: ['blur', 'change'] }],
        HD_Render_Flag: [{ required: true, message: this.$t('config.select_HD_Render_Flag'), trigger: ['blur', 'change'] }],
        groupName: [{ required: true, message: this.$t('addGroup.groupNameRemind'), trigger: 'blur' }],
        Min_BR: [{ validator: validateMin_BR, trigger: ['blur', 'change'] }],
        Max_BR: [{ validator: validateMax_BR, trigger: ['blur', 'change'] }],
        Max_FPS: [{ validator: validateMax_FPS, trigger: ['blur', 'change'] }],
        Max_RES_Width: [{ validator: validateMax_RES_WIDTH, trigger: ['blur', 'change'] }],
        Max_RES_Height: [{ validator: validateMax_RES_HEIGHT, trigger: ['blur', 'change'] }]
      },
      toolTip: {
        zh: {
          rmtop_ultra: '远程实时交互控制超高画质',
          rmtop_high: '远程实时交互控制高画质',
          rmtop_mid: '远程实时交互控制中画质',
          rmtop_base: '远程实时交互控制基础画质',
          rmtop_air: '飞行远程实时交互控制',
          rmtop_weak: '弱网下远程实时交互控制',
          rmtwi_high: '远程弱交互控制高画质',
          rmtwi_mid: '远程弱交互控制中画质',
          rmtni_high: '远程监看高画质',
          rmtni_mid: '远程监看中画质',
          rmtni_base: '远程监看基础画质',
          cgame: '云游戏',
          chat: '音频交互，兼顾视频',
          recordV: '视频交互，兼顾音频',
          chorus: '超低延时合唱',
          band: '超低延时乐队合奏',
          music: '音乐教学',
          recordv: '视频交互为主，兼顾音频',
          rmtop_dynamic: '延时、画质动态调整，适合飞行远程交互控制等需要一定实时性，而网络变化较大的场景',
          chat_weak_base: '弱网下的音频交互控制，兼顾视频，低画质',
          chat_weak_mid: '弱网下的音频交互控制，兼顾视频，中画质',
          rmtwip_ultra: '远程弱交互控制高画质',
          rmtwip_mid: '远程弱交互控制中画质'
        },
        en: {
          rmtop_ultra: 'Remote real-time interactive control with ultra-high image quality',
          rmtop_high: 'Remote real-time interactive control with high image quality',
          rmtop_mid: 'Remote real-time interactive control of medium picture quality',
          rmtop_base: 'Remote real-time interactive control of basic image quality',
          rmtop_air: 'Remote real-time interactive control of flight',
          rmtop_weak: 'Remote real-time interactive control under weak network',
          rmtwi_high: 'Remote weak interactive control high image quality',
          rmtwi_mid: 'Remote weak interactive control medium picture quality',
          rmtni_high: 'High-definition remote monitoring',
          rmtni_mid: 'Remote monitoring medium picture quality',
          rmtni_base: 'Remote monitoring basic picture quality',
          cgame: 'cloud gaming',
          chat: 'Audio interaction, taking into account video',
          recordV: 'Video interaction, taking into account audio',
          chorus: 'Ultra low latency chorus',
          band: 'Ultra Low Latency Band Ensemble',
          music: 'music teaching',
          recordv: 'Mainly for video interaction, taking into account audio',
          rmtop_dynamic: 'Time delay, dynamic adjustment of image quality, suitable for scenarios that require a certain real-time performance, such as remote interactive control of flight, and the network changes greatly',
          chat_weak_base: 'Audio interactive control under weak network, taking into account video, low image quality',
          chat_weak_mid: 'Audio interactive control under weak network, taking into account video, medium picture quality',
          rmtwip_ultra: 'Remote weak interactive control high image quality',
          rmtwip_mid: 'Remote weak interactive control medium picture quality'
        }
      }
    }
  },
  watch: {
    tmp: {
      handler () {
        for (const key in this.tmp) {
          if (key !== 'BASE') {
            this.tmp[key].SE_Name = this.tmp.PROFILE_0.SE_Name
          }
        }
      },
      deep: true
    }
  },
  created () {
    this.selectList()
    this.getAllGroupName()
    this.getTmp('template')
  },
  methods: {
    // 获取下拉列表
    async selectList () {
      const getDisplayModeList = get_sename_list({ select_name: 'display_mode' })
      const getPslVerboseLogList = get_sename_list({ select_name: 'psl_verbose_log' })
      const getPtcpLogList = get_sename_list({ select_name: 'ptcp_log' })
      const getHD_Decode_FlagList = get_sename_list({ select_name: 'HD_Decode_Flag' })
      const getHD_Render_FlagList = get_sename_list({ select_name: 'HD_Render_Flag' })

      const res1 = await getDisplayModeList
      const res2 = await getPslVerboseLogList
      const res3 = await getPtcpLogList
      const res4 = await getHD_Decode_FlagList
      const res5 = await getHD_Render_FlagList

      this.displayModeList = res1.data.data
      this.pslVerboseLogList = res2.data.data
      this.ptcpLogList = res3.data.data
      this.HD_Decode_FlagList = res4.data.data
      this.HD_Render_FlagList = res5.data.data
    },
    // 修改场景名称
    handleChange (value, idx) {
      this.limitList[idx] = this.profileConfig[value]
      this.currentField = idx
      console.log(this.limitList)
      console.log(this.limitList[idx])
    },
    // 获取所有的group
    async getAllGroupName () {
      const res = await get_all_groupName()
      if (res.data.code === 0) {
        this.groupOptions = res.data.data
      }
    },
    // 添加profile配置
    async addProfile () {
      const len = Object.keys(this.tmp).length
      const name = `PROFILE_${len - 1}`
      this.limitList[name] = this.profileConfig[this.tmp.PROFILE_0.SE_Name]
      this.nameArr.push(name)
      console.log(this.limitList)
      const res = await get_group_detail({ group: 'template' })
      if (res.data.code === 0) {
        this.profileTmp = res.data.data.PROFILE_0
        console.log(this.profileTmp)
      }
      this.$set(this.tmp, name, this.profileTmp)
      this.activeName = name
    },
    // 删除profile
    deleteProfile (idx) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        this.$set(this.tmp, idx, undefined)
        delete this.tmp[idx]
      }).catch(() => {})
    },
    // 复制选择的group配置到当前group
    copyConfig () {
      if (this.copyGroup) {
        this.getTmp(this.copyGroup)
      } else {
        this.$alert(this.$t('config.copyGroup'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm')
        })
      }
    },
    // 修改当前字段，用于校验不同PROFILE的值
    changeField (field) {
      this.currentField = field
    },
    // 获取场景名称以及场景名称的参数限制
    async getProfileConfig () {
      const res = await get_profile_config()
      if (res.data.code === 0) {
        this.profileConfig = res.data.data
        this.nameArr = Object.keys(this.tmp)
        this.nameArr.forEach((item) => {
          if (this.profileConfig[this.tmp[item].SE_Name]) {
            this.$set(this.limitList, item, this.profileConfig[this.tmp[item].SE_Name])
          } else {
            this.$set(this.limitList, item, {})
          }
        })
        delete this.limitList.BASE
        this.seNameList = Object.keys(this.profileConfig)
      }
    },
    goBack () {
      this.$router.back()
    },
    // 获取group名称为template的数据，用作添加group的默认数据
    async getTmp (group) {
      const res = await get_group_detail({ group })
      if (res.data.code === 0) {
        this.tmp = res.data.data
        this.limitName = this.tmp.PROFILE_0.SE_Name
        this.getProfileConfig()
      }
    },
    // 校验表单数据
    check () {
      this.checkResult = []
      this.$refs.addForm.forEach((item, index) => {
        this.currentField = this.nameArr[index]
        return item.validate((valid) => {
          this.checkResult.push(valid)
        })
      })
      console.log(this.checkResult)
    },
    // 确认添加group
    confirmAddGroup () {
      this.check()
      const result = this.checkResult.every((item) => {
        return item === true
      })
      if (result) {
        const param = {
          group_name: this.tmp.BASE.groupName,
          group_config: this.tmp
        }
        console.log(param)
        add_group(param).then((res) => {
          if (res.data.code === 0) {
            this.$message.success(this.$t('config.addgroupSuccess'))
            this.$router.push('/group/index')
          }
        })
      } else {
        this.$alert(this.$t('common.check'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm')
        })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.operate {
  position: sticky;
  background: #fff;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 20px;
  border-bottom: 1px solid #cecece;
  top: 0;
  left: 0;
  z-index: 100;
}
.reducePadding /deep/ .el-input__inner {
  padding-right: 16px;
}
.tool {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.profileDelete {
  position: absolute;
  right: 50px;
  top: 10px;
}
.penetrate /deep/ .el-collapse-item__wrap {
  overflow: visible !important;
}
</style>
